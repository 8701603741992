<template>
  <div>
    <b-card-actions
      :show-loading="fetchingCertificate"
      action-collapse
      title="Vencimento certificados digitais"
    >
      <b-table
        ref="certificate-table"
        show-empty
        responsive
        striped
        class="bordered"
        empty-text="Sem resultados"
        :fields="fields"
        :items="certificates"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(tradingName)="row">
          <e-router-link
            :title="$t('Visualizar Loja')"
            :to="{
              name: 'store-maintain',
              params: { id: row.item.id },
            }"
            :value="`${$options.filters.storeName(row.item)}`"
          />
        </template>
        <template #cell(digitalCertificateDueDate)="row">
          <e-status-badge
            :variant="row.item.color"
            type="noOptions"
            :status="row.item.elapsedTime"
          />
        </template>
      </b-table>
      <b-row>
        <b-col>
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col>
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="order-purchase-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>
  </div>
</template>

<script>
import { BRow, BCol, BTable, BPagination } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapState } from 'vuex'
import { EStatusBadge, EPaginationSummary } from '@/views/components'
import ERouterLink from '@/views/components/ERouterLink.vue'

export default {
  components: {
    BCardActions,
    BTable,
    BRow,
    BCol,
    BPagination,
    EStatusBadge,
    EPaginationSummary,
    ERouterLink,
  },

  data() {
    return {
      fetchingCertificate: false,
    }
  },

  computed: {
    ...mapState('pages/dashboard/expiredCertificate', {
      paging: 'paging',
      certificates: 'certificates',
      sorting: 'sorting',
    }),

    fields() {
      return [
        {
          label: this.$t('Loja'),
          key: 'tradingName',
          thClass: 'text-center',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Vencimento'),
          key: 'digitalCertificateDueDate',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
      ]
    },
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    ...mapActions('pages/dashboard/expiredCertificate', [
      'fetchCertificate',
      'setCurrentPage',
      'setPageSize',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetchingCertificate = true
          await this.fetchCertificate()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetchingCertificate = false
        }
      })
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },
  },
}
</script>

<style></style>
